<template>
    <div class="storeDecorate-container">
        <div class="storeSign-upload">
            <div class="storeSign-img">
                <img :src="dzInfo.img_url ? dzInfo.img_url : require('../../../assets/images/noimg.png')" alt="">
            </div>
            <el-upload class="upload-demo"
                    :action="uploadCover"
                    :headers="headersParams"
                    name="file"
                    accept=".jpg, .png, .jpeg"
                    :show-file-list="false"
                    :on-success="handleCoverSuccess"
                    :before-upload="beforeCoverUpload">
                <el-button icon="el-icon-upload2" style="width: 248px; margin-top: 20px" class="btn-red-line">点击上传</el-button>
            </el-upload>
        </div>
        <div class="storeSign-prompt">
            <i class="iconfont">&#xe72a;</i>
<!--            <span class="title">提示</span>上传尺寸为1920px*120px，仅支持jpg、jpeg、png格式,每张图片小于等于2M-->
            <span class="title">提示</span>上传尺寸为950px*120px，仅支持jpg、jpeg、png格式,每张图片小于等于3M
        </div>
        <div style="height: 1%; flex: 1"></div>
        <div class="storeSign-btn">
            <el-button class="btn-red" @click="submitBtn(dzInfo)">提交</el-button>
            <router-link tag="a" target="_blank" :to="{path: '/student/store/index'}"><el-button class="btn-red btn-margin">预览</el-button></router-link>
        </div>
        <div class="mask-cover" v-if="competitionType === 1 && menus[2].state === 0">
            <div class="prompt">
                <i class="iconfont">&#xe724;</i>请先下载</div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import router from "../../../router";
    export default {
        data() {
            return {
                dzInfo: {
                    id: '',
                    competition_id: '',
                    img_url: '', // 无域名 表单提交
                    img: '', // 有域名
                },
                // S 上传图片需要
                uploadCover: this.$api.decorateUploadDianzhao,
                headersParams: {
                    Authorization: localStorage.getItem('token')
                },
                // E 上传图片需要
                submitStatus: null,
                competitionType: this.$lockr.get('competition_type')
            };
        },
        computed: {
            menus() {
                return this.$store.getters.decorationMenuList;
            }
        },
        mounted() {
            this.getDianzhaoInfo()
            if (this.competitionType === 1) {
                /*if (!localStorage.getItem('menuList')) {
                    this.getMenu();
                }
                if (localStorage.getItem('menuList')) {
                    let menuTmp = JSON.parse(localStorage.getItem('menuList'))
                    if (menuTmp.length > 0) {
                        this.submitStatus = JSON.parse(localStorage.getItem('menuList'))[2].state
                    }
                }
                this.$lsbridge.subscribe('updateStatus', () => {
                    this.submitStatus = JSON.parse(localStorage.getItem('menuList'))[2].state
                })*/
                this.updateMenus();
                let menus = this.menus;
                this.submitStatus = menus[2].state;
                // this.updateMenus();

            }
        },
        methods: {
            ...mapActions([
               'setDecorationMenusList'
            ]),
            /*getMenu() {
                this.$http.axiosGet(this.$api.decorate_zhuangxiuStateList, (res) => {
                    if (res.code === 200) {
                        let menuList = res.data;
                        if (menuList.length > 0) {
                            this.submitStatus = menuList[2].state
                        }
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },*/
            updateMenus() {
                if (this.$common.isCompetion()) {
                    this.setDecorationMenusList();
                }
            },
            // 获取店招信息
            getDianzhaoInfo() {
                this.$http.axiosGet(this.$api.decorateDianzhaoInfo, (res) => {
                    if (res.code === 200) {
                        if (res.data) {
                            this.dzInfo = res.data
                            this.updateMenus();
                        }
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // start 上传图片
            beforeCoverUpload(file) {
                // const isImg = file.name.toLowerCase().endsWith('.jpg') || file.name.toLowerCase().endsWith('.jpeg') || file.name.toLowerCase().endsWith('.png');
                // const isLt2M = file.size / 1024 / 1024 < 2;
                const isLt2M = file.size / 1024 / 1024 <= 3;
                // if (!isImg) {
                //     this.$message.error('上传图片只能是 jpg 或 jpeg 或 png 格式!');
                //     return false;
                // }
                if (!isLt2M) {
                    this.$message.error('上传图片大小不能超过 3MB!');
                    return false;
                }
                const isSize = new Promise((resolve, reject) => {
                  const width = 950
                  const height = 120
                  const _URL = window.URL || window.webkitURL
                  const img = new Image()
                  img.onload = () => {
                    const valid = img.width === width && img.height === height
                    valid ? resolve() : reject()
                  }
                  img.src = _URL.createObjectURL(file)
                }).then(() => {
                  return file
                }, () => {
                  this.$message.warning('上传尺寸为950px*120px')
                  return Promise.reject()
                })
                return isLt2M && isSize
            },
            handleCoverSuccess(res, file) {
                if (res.code === 200) {
                    this.dzInfo.img_url = res.data
                    this.$message.success(res.msg)
                } else if (res.code === 4000) {
                    this.$alert('比赛结束啦！', '提示消息', {
                        confirmButtonText: '确定',
                    }).then(() => {
                        localStorage.clear()
                        this.$router.push({
                            path: '/',
                        })
                    });
                } else {
                    this.$message.error(res.msg)
                }
            },
            // end 上传图片
            // 提交
            submitBtn(info) {
                if (!this.dzInfo.img_url) {
                    this.$message.warning('请上传店招')
                    return false
                }

                let formData = new FormData()
                formData.append('img_url', this.dzInfo.img_url)

                if (info.id) {
                    formData.append('id', info.id)
                }

                this.$http.axiosPost(this.$api.decorateSavaDianzhao, formData, (res) => {
                    if (res.code === 200) {
                        this.$message.success(res.msg)
                        this.getDianzhaoInfo()
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .mask-cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .5);
        /*background: rgba(255, 255, 255, .5);*/
        display: flex;
        align-items: center;
        justify-content: center;
        .prompt {
            color: #fff;
            font-size: 30px;
            .iconfont {
                font-size: 30px;
                margin-right: 10px;
            }
        }
    }
    .storeDecorate-container {
        background: #fff;
        min-height: calc(100vh - 120px);
        box-sizing: border-box;
        padding: 30px 90px 50px;
        display: flex;
        flex-direction: column;
        .storeSign-upload {
            .storeSign-img {
                width: 100%;
                height: 0;
                padding-bottom: 6.25%;
                position: relative;
                background: #fafafa;
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
        .storeSign-prompt {
            color: #999;
            margin-top: 20px;
            min-height: 45px;
            border: 1px solid #DCDCDC;
            line-height: 21px;
            padding: 10px 20px;
            box-sizing: border-box;
            .iconfont {
                color: #FF0000;
            }
            .title {
                color: #333;
                margin: 0 20px;
            }
        }
        .storeSign-btn {
            text-align: center;
        }
    }
</style>